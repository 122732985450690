import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "notifications"
    }}>{`Notifications`}</h1>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Patrons can opt in to receive push notifications from your mobile app from within their account information. `}<em parentName="li">{`If patrons are allowed to switch between templates, and have opted in for notifications on multiple templates, the app will only send notifciations based on the currently load template.`}</em></li>
      <li parentName="ol">{`Toggle on desired notifications:`}
        <ul parentName="li">
          <li parentName="ul">{`Library News are for Ad-Hoc Push Notifications. To learn how to create these types of notifications, please refer to the `}<a parentName="li" {...{
              "href": ""
            }}>{`Configure Ad-Hoc Push Notifications`}</a>{` page for help. `}</li>
          <li parentName="ul">{`Loans & Reservations are related to the patrons account. To learn how to create these types of notifications, please refer to the `}<a parentName="li" {...{
              "href": ""
            }}>{`Configure Account Push Notifications`}</a>{` page for help. `}</li>
          <li parentName="ul">{`Events are for reminders about events and room bookings that patron has signed up for. `}</li>
        </ul>
      </li>
    </ol>
    <p><img alt="My Account Screen" src={require("./images/my_account.png")} />{` `}<img alt="Peferences Screen" src={require("./images/notifications_opt_in.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      